const availableRoutes = [
  // #################################################################################################################
  // Non-Navigation Routes
  // #################################################################################################################
  {
    path: "/patient/:id",
    name: "PatientDetails",
    component: () => import("@/views/PatientDetails/PatientDetails.vue"),
    meta: {
      name: "Patient Details",
      navItem: false,
      navItemImage: "",
      navItemSort: 0,
      navItemEnabled: true,
    },
  },
  {
    path: "/print-patient/:id",
    name: "PrintPatient",
    component: () => import("@/views/PrintPatient/PrintPatient.vue"),
    meta: {
      name: "Patient Details",
      navItem: false,
      navItemImage: "",
      navItemSort: 0,
      navItemEnabled: false,
    },
  },
  {
    path: "/patient/:id/record/:recId/key/:keyId",
    name: "RecordDetails",
    component: () => import("@/views/PatientDetails/PatientDetails.vue"),
    meta: {
      name: "Patient Details",
      navItem: false,
      navItemImage: "",
      navItemSort: 0,
      navItemEnabled: true,
    },
  },
  // {
  //   path: "/EMRPrintOut/:id",
  //   name: "EMRPrintOut",
  //   component: EMRPrintOut,
  //   meta: {
  //     name: "Patient Details",
  //     navItem: false,
  //     navItemImage: "",
  //     navItemSort: 0,
  //     navItemEnabled: false
  //   },
  // },
  {
    path: "/messages",
    name: "Messages",
    component: () => import("@/views/Messages/Messages.vue"),
    meta: {
      name: "Messages",
      navItem: true,
      navItemImage: "message.svg",
      navItemIcon: "MessagesIcon",
      navItemSort: 40,
      navItemEnabled: true,
    },
  },

  // #################################################################################################################
  // Navigation Routes
  // #################################################################################################################

  {
    path: "/pharmacy",
    name: "Pharmacy",
    component: () => import("@/views/Pharmacy/Pharmacy.vue"),
    meta: {
      name: "Patient Details",
      navItem: false,
      navItemImage: "pharmacy.svg",
      navItemSort: 20,
      navItemEnabled: false,
    },
  },
  {
    path: "/appointments",
    name: "Appointments",
    component: () => import("@/views/Appointments/Appointments.vue"),
    meta: {
      name: "Schedule",
      navItem: true,
      navItemImage: "schedule.svg",
      navItemIcon: "CalendarIcon",
      navItemSort: 30,
      navItemEnabled: false,
    },
  },
  {
    path: "/records",
    name: "Records",
    component: () => import("@/views/Records/Records.vue"),
    meta: {
      name: "Charts",
      navItem: true,
      navItemImage: "checklist.svg",
      navItemIcon: "ListIcon",
      navItemSort: 50,
      navItemEnabled: true,
    },
  },
  {
    path: "/activity",
    name: "Activity",
    component: () => import("@/views/Activity/Activity.vue"),
    meta: {
      name: "BedScribe",
      navItem: true,
      navItemImage: "bedscribe-chart.svg",
      navItemIcon: "BedScribeIcon",
      navItemSort: 60,
      navItemEnabled: true,
    },
  },
  {
    path: "/config",
    name: "Config",
    component: () => import("@/views/settings/SettingsView.vue"),
    meta: {
      name: "Settings",
      navItem: true,
      navItemImage: "",
      navItemIcon: "SettingsIcon",
      navItemSort: 80,
      navItemEnabled: true,
    },
  },
];

export default availableRoutes;
