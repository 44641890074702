<template>
  <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <g id="icon / message">
      <path
        id="Union"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.25 12.5C3.25 7.39136 7.39136 3.25 12.5 3.25C17.6086 3.25 21.75 7.39136 21.75 12.5C21.75 17.6086 17.6086 21.75 12.5 21.75H3.25V12.5ZM1.75 12.5V22.4999C1.75 22.9141 2.08587 23.25 2.50008 23.25H12.5C18.4371 23.25 23.25 18.4371 23.25 12.5C23.25 6.56294 18.4371 1.75 12.5 1.75C6.56294 1.75 1.75 6.56294 1.75 12.5ZM7.5 8.75C7.08579 8.75 6.75 9.08579 6.75 9.5C6.75 9.91421 7.08579 10.25 7.5 10.25H16.5C16.9142 10.25 17.25 9.91421 17.25 9.5C17.25 9.08579 16.9142 8.75 16.5 8.75H7.5ZM6.75 13.5C6.75 13.0858 7.08579 12.75 7.5 12.75H16.5C16.9142 12.75 17.25 13.0858 17.25 13.5C17.25 13.9142 16.9142 14.25 16.5 14.25H7.5C7.08579 14.25 6.75 13.9142 6.75 13.5ZM7.5 16.75C7.08579 16.75 6.75 17.0858 6.75 17.5C6.75 17.9142 7.08579 18.25 7.5 18.25H12.5C12.9142 18.25 13.25 17.9142 13.25 17.5C13.25 17.0858 12.9142 16.75 12.5 16.75H7.5Z"
      />
    </g>
  </svg>
</template>
