<template>
  <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <g id="icon / schedule">
      <path
        id="Union"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.5 2.75C7.91421 2.75 8.25 3.08579 8.25 3.5V4.75H16.75V3.5C16.75 3.08579 17.0858 2.75 17.5 2.75C17.9142 2.75 18.25 3.08579 18.25 3.5V4.75H21.5C22.4665 4.75 23.25 5.5335 23.25 6.5V19.5C23.25 20.4665 22.4665 21.25 21.5 21.25H3.5C2.5335 21.25 1.75 20.4665 1.75 19.5V6.5C1.75 5.5335 2.5335 4.75 3.5 4.75H6.75V3.5C6.75 3.08579 7.08579 2.75 7.5 2.75ZM16.75 6.25V7.5C16.75 7.91421 17.0858 8.25 17.5 8.25C17.9142 8.25 18.25 7.91421 18.25 7.5V6.25H21.5C21.6381 6.25 21.75 6.36193 21.75 6.5V19.5C21.75 19.6381 21.6381 19.75 21.5 19.75H3.5C3.36193 19.75 3.25 19.6381 3.25 19.5V6.5C3.25 6.36193 3.36193 6.25 3.5 6.25H6.75V7.5C6.75 7.91421 7.08579 8.25 7.5 8.25C7.91421 8.25 8.25 7.91421 8.25 7.5V6.25H16.75ZM7.5 11.25C7.08579 11.25 6.75 11.5858 6.75 12C6.75 12.4142 7.08579 12.75 7.5 12.75H13C13.4142 12.75 13.75 12.4142 13.75 12C13.75 11.5858 13.4142 11.25 13 11.25H7.5ZM6.75 16C6.75 15.5858 7.08579 15.25 7.5 15.25H17.5C17.9142 15.25 18.25 15.5858 18.25 16C18.25 16.4142 17.9142 16.75 17.5 16.75H7.5C7.08579 16.75 6.75 16.4142 6.75 16Z"
      />
    </g>
  </svg>
</template>
