<template>
  <div class="modal-wrap">
    <div class="content">
      <div class="success" v-if="success">
        <div class="body">
          <span class="header">Updated Appointment Time Sent</span>
          <span class="message">Appointment is pending until approved by patient</span>
        </div>
        <div class="footer">
          <div class="button-new">
            <button @click="closeModal">
              <span>Close</span>
            </button>
          </div>
        </div>
      </div>
      <div class="new-appointment" v-if="!success">
        <div class="header">
          <span>Suggest New Time</span>
          <img src="@/assets/icons/close.svg" alt="" @click="closeModal" />
        </div>
        <div class="body">
          <div class="name">
            <label for="name">Name:</label>
            <span id="name"
              >{{ currentPendingAppointment.firstName }}
              {{ currentPendingAppointment.lastName }}</span
            >
          </div>
          <div class="date">
            <label for="date">Requested Appointment:</label>
            <span id="date">{{ currentPendingAppointment.from }}</span>
          </div>
          <div class="reason">
            <label for="reason">Reason for Visit:</label>
            <span id="reason">{{ currentPendingAppointment.reasonForVisit }}</span>
          </div>
          <div class="new">
            <label>Suggest New Date & Time:</label>
            <div class="datepicker">
              <Datepicker
                class="new-date"
                v-model="newDate"
                utc
                placeholder="Select Date & Time"
                :is24="false"
                required
                textInput
              />
            </div>
          </div>
          <div class="type">
            <label for="type">Type:</label>
            <select v-model="type" id="type">
              <option :value="null">{{ currentPendingAppointment.type }}</option>
              <option value="In-person">In-person</option>
              <option value="Conference">Conference</option>
              <option value="Telemedicine">Telemedicine</option>
            </select>
          </div>
        </div>
        <div class="footer">
          <div class="button-delete">
            <button @click="deleteAppointment(currentPendingAppointment.id)">
              <span>Delete</span>
            </button>
          </div>
          <button class="close" @click="closeModal">Cancel</button>
          <div class="button-new">
            <button @click="suggestNewTime(currentPendingAppointment.id)">
              <span>Suggest New Time</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SocketClient from "@/sockets/SocketClients.ts";
import { mapActions, mapMutations, mapState } from "vuex";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { ref } from "vue";

export default {
  name: "NewTimeModal",
  components: {
    Datepicker,
  },
  data() {
    return {
      socketClient: SocketClient,
      currentPendingAppointment: null,
      newDate: ref(),
      type: null,
      success: false,
    };
  },
  beforeMount() {
    this.socketClient = SocketClient.create(true);
    this.currentPendingAppointment = this.currentPendingArray[0];
  },
  methods: {
    ...mapActions(["GET_APPOINTMENTS"]),
    ...mapMutations(["TOGGLE_NEW_TIME_MODAL", "DELETE_APPOINTMENTLIST"]),

    closeModal() {
      this.TOGGLE_NEW_TIME_MODAL();
    },

    async suggestNewTime(id) {
      const data = {
        id,
        status: "patient pending",
        from: this.newDate,
      };
      await this.socketClient.updateAppointmentById(data);
      await this.DELETE_APPOINTMENTLIST();
      await this.socketClient.getAppointmentList();
      await this.socketClient.initGetAppointmentListListener((appointmentList) => {
        this.GET_APPOINTMENTS({ data: appointmentList });
      });
      this.success = !this.success;
    },

    async deleteAppointment(id) {
      const data = {
        id,
      };
      await this.socketClient.deleteAppointmentById(data);
      await this.DELETE_APPOINTMENTLIST();
      await this.socketClient.getAppointmentList();
      await this.socketClient.initGetAppointmentListListener((appointmentList) => {
        this.GET_APPOINTMENTS({ data: appointmentList });
      });
      this.closeModal();
    },
  },
  computed: {
    ...mapState(["currentPendingArray"]),
  },
};
</script>

<style lang="scss" scoped>
@import "newTimeModal.scss";
</style>
