<template>
  <div class="modal-wrap">
    <div class="content">
      <div class="success" v-if="success">
        <div class="body">
          <span class="header">New User Created</span>
        </div>
        <div class="footer">
          <div class="button-new">
            <button @click="closeModal">
              <span>Close</span>
            </button>
          </div>
        </div>
      </div>
      <div class="new-user" v-if="!success">
        <div class="header">
          <span>New User</span>
          <img src="@/assets/icons/close.svg" alt="" @click="closeModal" />
        </div>
        <div class="body">
          <div class="left">
            <div class="prefix">
              <label>Prefix:</label>
              <input type="text" v-model="prefix" required />
            </div>
            <div class="last-name">
              <label>Last Name:</label>
              <input type="text" v-model="lastName" required @input="acceptChar('lastName')" />
            </div>
            <div class="dob">
              <label>Date of Birth:</label>
              <div class="datepicker">
                <Datepicker
                  class="new-date"
                  v-model="dateOfBirth"
                  utc
                  :enableTimePicker="false"
                  placeholder="Select Date"
                  format="yyyy-MM-dd"
                  required
                />
              </div>
            </div>
            <div class="address1">
              <label>Address Line 1:</label>
              <input type="text" v-model="address.line1" required />
            </div>
            <div class="city">
              <label>City:</label>
              <input type="text" required v-model="address.city" @input="acceptChar('city')" />
            </div>
            <div class="zip">
              <label>Zip:</label>
              <input required type="text" v-model="address.zip" @input="acceptZip" />
            </div>
          </div>
          <div class="right">
            <div class="first-name">
              <label>First Name:</label>
              <input type="text" v-model="firstName" required @input="acceptChar('firstName')" />
            </div>
            <div class="suffix">
              <label>Suffix:</label>
              <input type="text" v-model="suffix" required />
            </div>
            <div class="phone">
              <label>Phone:</label>
              <input required type="text" v-model="phone" @input="acceptNumber" />
            </div>
            <div class="address2">
              <label>Address Line 2:</label>
              <input type="text" v-model="address.line2" />
            </div>
            <div class="state">
              <label>State:</label>
              <select v-model="address.state" required>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
            </div>
            <div class="clinic">
              <label>Clinic:</label>
              <input required type="text" v-model="clinic" />
            </div>
          </div>
        </div>
        <div class="login">
          <div class="email">
            <label>Email:</label>
            <input required type="email" v-model="email" />
          </div>
          <div class="password">
            <label>Password:</label>
            <input required type="password" v-model="password" />
          </div>
          <div class="confirm-password">
            <label>Confirm Password:</label>
            <input required type="password" v-model="confirmPassword" />
          </div>
        </div>
        <div class="footer">
          <button class="close" @click="closeModal">Cancel</button>
          <div class="button-new">
            <button @click="createUser">
              <span>Add New Patient</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SocketClient from "@/sockets/SocketClients";
import { mapMutations } from "vuex";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  name: "NewUserModal",
  components: {
    Datepicker,
  },
  data() {
    return {
      socketClient: SocketClient,
      success: false,
      prefix: "",
      firstName: "",
      lastName: "",
      suffix: "",
      dateOfBirth: "",
      phone: "",
      address: {
        line1: "",
        line2: "",
        city: "",
        state: "",
        zip: "",
      },
      clinic: "",
      email: "",
      password: "",
      confirmPassword: "",
    };
  },
  methods: {
    ...mapMutations(["TOGGLE_NEW_USER_MODAL"]),

    closeModal() {
      this.TOGGLE_NEW_USER_MODAL();
    },

    acceptChar(name) {
      if (name === "firstName") {
        this.firstName = this.firstName.replace(/[^a-zA-Z]/g, "");
      }
      if (name === "lastName") {
        this.lastName = this.lastName.replace(/[^a-zA-Z]/g, "");
      }
      if (name === "city") {
        this.address.city = this.address.city.replace(/[^a-zA-Z]/g, "");
      }
    },

    acceptNumber() {
      const x = this.phone.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.phone = !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },

    acceptZip() {
      const x = this.address.zip.replace(/\D/g, "").match(/\d{0,5}/);
      this.address.zip = x[0];
    },

    async createUser() {
      if (
        !(
          this.firstName &&
          this.lastName &&
          this.dateOfBirth &&
          this.email &&
          this.password &&
          this.confirmPassword
        )
      ) {
        return false;
      }
      if (this.password !== this.confirmPassword) {
        return false;
      }
      const data = {
        prefix: this.prefix.charAt(0).toUpperCase() + this.prefix.slice(1),
        firstName: this.firstName.charAt(0).toUpperCase() + this.firstName.slice(1),
        lastName: this.lastName.charAt(0).toUpperCase() + this.lastName.slice(1),
        suffix: this.suffix.charAt(0).toUpperCase() + this.suffix.slice(1),
        dateOfBirth: this.dateOfBirth,
        phone: this.phone,
        address: this.address,
        clinic: this.clinic,
        email: this.email,
        password: this.password,
        confirmPassword: this.confirmPassword,
      };
      this.socketClient = SocketClient.create(true);
      this.socketClient.createUser(data);
      this.success = !this.success;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "NewUserModal";
</style>
