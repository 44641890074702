
import { defineComponent } from "vue";
import { mapState, mapActions } from "vuex";

import MessagesIcon from "@/components/icons/MessagesIcon.vue";
import { IMessage } from "@/sockets/types/Messages.types";

export default defineComponent({
  name: "Header",
  components: {
    MessagesIcon,
  },
  props: {
    showUserName: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      displayName: "Placeholder",
    };
  },
  created() {
    this.displayName = localStorage.getItem("displayName") as string;
    this.GET_MESSAGES_DATA();
  },
  computed: {
    // eslint-disable-next-line
    ...mapState({ unreadMessages: (state: any) => state.messages.unreadMessages }),
    // eslint-disable-next-line
    ...mapState({ totalUnreadMessages: (state: any) => state.messages.totalUnreadMessages }),
  },
  watch: {
    unreadMessages(newValue: IMessage[], oldValue: IMessage[]) {
      if (oldValue.length === 0) {
        this.setUnreadMessagesData();
      } else if (newValue.length === 1) {
        this.updateUnreadMessagesData();
      }
    },
  },
  methods: {
    ...mapActions(["GET_MESSAGES_DATA", "setUnreadMessagesData", "updateUnreadMessagesData"]),
  },
});
