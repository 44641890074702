
import { defineComponent } from "vue";

import SocketClient from "@/sockets/SocketClients";
import AuthService from "@/shared/services/AuthService";

import { mapActions, mapState } from "vuex";
import NewTimeModal from "@/components/NewTimeModal/NewTimeModal.vue";
import AppointmentDetailsModal from "@/components/AppointmentDetailsModal/AppointmentDetailsModal.vue";
import RefillRequestModal from "@/components/RefillRequestModal/RefillRequestModal.vue";
import NewUserModal from "@/components/NewUserModal/NewUserModal.vue";
import DefaultTemplate from "@/components/templates/DefaultTemplate.vue";

import availableRoutes from "@/router/routes";

export default defineComponent({
  components: {
    NewTimeModal,
    AppointmentDetailsModal,
    RefillRequestModal,
    NewUserModal,
    DefaultTemplate,
  },
  data() {
    return {
      socketClient: SocketClient,
    };
  },
  methods: {
    ...mapActions(["GET_USER"]),

    init() {
      // TODO:  Add check for access to localStorage.  Function: "isLocalStorage()"

      this.GET_USER(AuthService.getUserId());
      // this.socketClient.getAppointmentList();
      // this.socketClient.initGetAppointmentListListener((appointmentList) => {
      //   this.GET_APPOINTMENTS({ data: appointmentList });
      // });

      // Added dynamic routing, based off values from localStorage.
      let arrRouterAccess = JSON.parse(localStorage.getItem("routerAccess") as string);

      // Testing Data:
      if (!arrRouterAccess) {
        // No routes available.
        // TODO:  Send user to page that shows a navigation error.
        // window.location.replace(`https://${location.hostname}/`);
      } else {
        // Build out available routes, based off of ids from localStorage

        arrRouterAccess = arrRouterAccess.routerAccess;

        const arrRoutesToAdd = availableRoutes.filter(function (objRoute) {
          return arrRouterAccess.indexOf(objRoute.name) > -1;
        });

        arrRoutesToAdd.forEach((objRoute) => {
          console.log("Adding Route: " + objRoute.name);
          // console.log('Can I get to the router?');
          //console.log(this.$router);
          this.$router.addRoute(objRoute);
        }, this);
      }
    },
  },
  created() {
    this.init();
  },
  computed: {
    ...mapState([
      "patientListLoaded",
      "newPatientModal",
      "newTimeModal",
      "appointmentDetailsModal",
      "refillRequestModal",
      "newUserModal",
    ]),
    useTemplate(): boolean {
      return this.$route.name !== "PrintPatient";
    },
  },
});
