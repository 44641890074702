<template>
  <div class="modal-wrap">
    <div class="content">
      <div class="success" v-if="success">
        <div class="body">
          <span class="header">Refill Request Approved</span>
        </div>
        <div class="footer">
          <div class="button-new">
            <button @click="closeModal">
              <span>Close</span>
            </button>
          </div>
        </div>
      </div>
      <div class="refill-request" v-if="!success">
        <div class="header">
          <span
            >Refill Request for {{ currentPatient.firstName }} {{ currentPatient.lastName }}</span
          >
          <img src="@/assets/icons/close.svg" alt="" @click="closeModal" />
        </div>
        <div class="body">
          <div class="pending-refill" v-if="currentPendingRefill">
            <div
              class="meds-card-container"
              v-for="refill in currentPendingRefill"
              :key="refill.id"
            >
              <div class="meds-card">
                <div class="meds-card-header">
                  <span>{{ refill.name }}</span>
                </div>
                <div class="meds-card-body">
                  <div class="prescribed">
                    <label for="prescribed">Prescribed on</label>
                    <span id="prescribed">{{ refill.prescribedOn }}</span>
                  </div>
                  <div class="approved-by">
                    <label for="approved-by">Approved by</label>
                    <span id="approved-by">{{ refill.approvedBy }}</span>
                  </div>
                  <div class="refill">
                    <label for="refill">Refill Details</label>
                    <span id="refill">{{ refill.refillDetails }}</span>
                  </div>
                  <div class="pharmacy">
                    <label for="pharmacy">Pharmacy</label>
                    <span id="pharmacy">{{ refill.pharmacy }}</span>
                  </div>
                </div>
                <div class="meds-card-footer">
                  <div class="deny">
                    <button @click="denyRefill(refill.id)">
                      <div class="deny-inner">
                        <span>Deny</span>
                      </div>
                    </button>
                  </div>
                  <div class="approve">
                    <button @click="approveRefill(refill.id)">
                      <div class="approve-inner">
                        <span>Approve</span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="past-refill" v-if="currentPastRefill">
            <div class="meds-card-container" v-for="refill in currentPastRefill" :key="refill.id">
              <div class="meds-card">
                <div class="meds-card-header">
                  <span>{{ refill.name }}</span>
                </div>
                <div class="meds-card-body">
                  <div class="prescribed">
                    <label for="past-prescribed">Prescribed on</label>
                    <span id="past-prescribed">{{ refill.prescribedOn }}</span>
                  </div>
                  <div class="approved-by">
                    <label for="past-approved-by">Approved by</label>
                    <span id="past-approved-by">{{ refill.approvedBy }}</span>
                  </div>
                  <div class="refill">
                    <label for="past-refill">Refill Details</label>
                    <span id="past-refill">{{ refill.refillDetails }}</span>
                  </div>
                  <div class="pharmacy">
                    <label for="past-pharmacy">Pharmacy</label>
                    <span id="past-pharmacy">{{ refill.pharmacy }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import SocketClient from "@/sockets/SocketClients.ts";

export default {
  name: "RefillRequestModal",
  data() {
    return {
      socketClient: SocketClient,
      success: false,
      currentPatient: null,
    };
  },
  beforeMount() {
    this.socketClient = SocketClient.create(true);
    this.currentPatient = this.currentPatientArray[0];
  },
  methods: {
    ...mapActions(["GET_REFILLS"]),
    ...mapMutations([
      "TOGGLE_REFILL_REQUEST_MODAL",
      "DELETE_REFILL_LISTS",
      "DELETE_CURRENT_REFILL_LISTS",
    ]),

    closeModal() {
      this.TOGGLE_REFILL_REQUEST_MODAL();
      this.DELETE_CURRENT_REFILL_LISTS();
    },

    async approveRefill(id) {
      const data = {
        id,
        refillStatus: "past",
        status: "current",
        prescribedOn: Date.now(),
      };
      await this.socketClient.updateMedById(data);
      await this.DELETE_REFILL_LISTS();
      await this.socketClient.getMedsList();
      await this.socketClient.initGetMedsListListener((medsList) => {
        this.GET_REFILLS({ data: medsList });
      });
      this.success = !this.success;
    },

    async denyRefill(id) {
      const data = {
        id,
        refillStatus: "past",
        status: "past",
      };
      await this.socketClient.updateMedById(data);
      await this.DELETE_REFILL_LISTS();
      await this.socketClient.getMedsList();
      await this.socketClient.initGetMedsListListener((medsList) => {
        this.GET_REFILLS({ data: medsList });
      });
      this.closeModal();
    },
  },
  computed: {
    ...mapState(["currentPendingRefill", "currentPatientArray", "currentPastRefill"]),
  },
};
</script>

<style lang="scss" scoped>
@import "RefillRequestModal";
</style>
