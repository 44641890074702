<template>
  <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <g id="icon / home">
      <path
        id="Union"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0315 2.91435C12.3054 2.69522 12.6946 2.69522 12.9685 2.91435L20.4685 8.91435L22.9685 10.9143C23.292 11.1731 23.3444 11.6451 23.0857 11.9685C22.8269 12.292 22.355 12.3444 22.0315 12.0857L20.75 11.0605V21.5C20.75 21.9142 20.4142 22.25 20 22.25H15H10H5.00003C4.58581 22.25 4.25003 21.9142 4.25003 21.5V11.0605L2.96855 12.0857C2.6451 12.3444 2.17313 12.292 1.91438 11.9685C1.65562 11.6451 1.70806 11.1731 2.03151 10.9143L4.53151 8.91435L4.53164 8.91424L12.0315 2.91435ZM5.75003 20.75H9.25003V15C9.25003 14.5858 9.58581 14.25 10 14.25H15C15.4142 14.25 15.75 14.5858 15.75 15V20.75H19.25V9.86047L12.5 4.46047L5.75003 9.86047V20.75ZM14.25 20.75V15.75H10.75V20.75H14.25Z"
      />
    </g>
  </svg>
</template>
