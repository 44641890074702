<template>
  <div class="modal-wrap">
    <div class="content">
      <div class="appointment-details">
        <div class="header">
          <span>Appointment Details</span>
          <img src="@/assets/icons/close.svg" alt="" @click="closeModal" />
        </div>
        <div class="body">
          <div class="name">
            <label for="name">Name:</label>
            <span id="name"
              >{{ currentAppointment.firstName }} {{ currentAppointment.lastName }}</span
            >
          </div>
          <div class="date">
            <label for="date">Requested Appointment:</label>
            <span id="date">{{ currentAppointment.from }}</span>
          </div>
          <div class="reason">
            <label for="reason">Reason for Visit:</label>
            <span id="reason">{{ currentAppointment.reasonForVisit }}</span>
          </div>
          <div class="type">
            <label for="type">Type:</label>
            <span id="type">{{ currentAppointment.type }}</span>
          </div>
          <div class="status">
            <label for="status">Status:</label>
            <span id="status">{{ currentAppointment.status }}</span>
          </div>
        </div>
        <div class="footer" v-if="currentAppointment.status === 'pending'">
          <button @click="toggleNewTimeModal(currentAppointment.id)">Suggest New Time</button>
          <div class="button-new">
            <button @click="approveAppointment(currentAppointment.id)">
              <span>Approve</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SocketClient from "@/sockets/SocketClients.ts";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "AppointmentDetailsModal",
  data() {
    return {
      socketClient: SocketClient,
      currentAppointment: null,
    };
  },
  beforeMount() {
    this.socketClient = SocketClient.create(true);
    if (this.currentPendingArray.length !== 0) {
      this.currentAppointment = this.currentPendingArray[0];
    }
    if (this.currentPatientPendingArray.length !== 0) {
      this.currentAppointment = this.currentPatientPendingArray[0];
    }
    if (this.currentConfirmedArray.length !== 0) {
      this.currentAppointment = this.currentConfirmedArray[0];
    }
    if (this.currentPastArray.length !== 0) {
      this.currentAppointment = this.currentPastArray[0];
    }
  },
  methods: {
    ...mapActions(["GET_APPOINTMENTS"]),
    ...mapMutations([
      "TOGGLE_NEW_TIME_MODAL",
      "DELETE_APPOINTMENTLIST",
      "TOGGLE_APPOINTMENT_DETAILS_MODAL",
      "DELETE_CURRENT_APPOINTMENT_LISTS",
    ]),

    closeModal() {
      this.DELETE_CURRENT_APPOINTMENT_LISTS();
      this.TOGGLE_APPOINTMENT_DETAILS_MODAL();
    },

    async approveAppointment(id) {
      const data = {
        id,
        status: "confirmed",
      };
      await this.socketClient.updateAppointmentById(data);
      await this.DELETE_APPOINTMENTLIST();
      await this.socketClient.getAppointmentList();
      await this.socketClient.initGetAppointmentListListener((appointmentList) => {
        this.GET_APPOINTMENTS({ data: appointmentList });
      });
      this.closeModal();
    },

    toggleNewTimeModal() {
      this.TOGGLE_APPOINTMENT_DETAILS_MODAL();
      this.TOGGLE_NEW_TIME_MODAL();
    },
  },
  computed: {
    ...mapState([
      "currentPendingArray",
      "currentPatientPendingArray",
      "currentConfirmedArray",
      "currentPastArray",
    ]),
  },
};
</script>

<style lang="scss" scoped>
@import "AppointmentDetailsModal";
</style>
