import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import availableRoutes from "@/router/routes";
import AuthService from "@/shared/services/AuthService";

import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

const baseRoutes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home/Home.vue"),
    meta: {
      name: "Home",
      navItem: true,
      navItemImage: "home.svg",
      navItemIcon: "HomeIcon",
      navItemSort: 10,
      navItemEnabled: false,
    },
  },
  {
    path: "/logout",
    name: "Logout",
    // TODO: uncomment when the new login works
    // beforeEnter: (): void => {
    //   AuthService.logout();
    // },
    component: () => {
      setTimeout(() => {
        window.location.replace(`https://${location.host}/login`);
        // TODO: uncomment when the new login works
        // window.location.replace("https://med.retinus.io/temporal");
      }, 300);
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "InvalidRoute",
    component: import("@/views/Home/Home.vue"),
    meta: {
      name: "Invalid Route",
      navItem: false,
      navItemImage: "",
      navItemSort: 0,
      navItemEnabled: false,
    },
  },
];

const routes: RouteRecordRaw[] = baseRoutes.concat(availableRoutes);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(
  (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    if (!AuthService.isAuthenticated() && to.name !== "Logout") {
      return next({ name: "Logout" });
    }

    //let blnAllowNext = false;
    let strDocumentTitle = "";

    if (to.meta) {
      // if ((to.meta.roles) && (store.state.role)) {
      //   Object.values(to.meta.roles).forEach(role => {
      //     if ((role == store.state.role) || (blnAllowNext)) {
      //       blnAllowNext = true;
      //     }
      //   });

      // } else {
      //   blnAllowNext = true;
      // }

      if (to.meta.name) {
        strDocumentTitle = String(to.meta.name);
      }
    }

    if (strDocumentTitle == "") {
      strDocumentTitle = String(to.name);
    }
    document.title = strDocumentTitle;
    //if (blnAllowNext) {
    return next();
    //} else {
    //  return false;
    //}
  }
);

export default router;
