// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { createStore } from "vuex";
import SocketClient from "@/sockets/SocketClients.ts";
import modalStorage from "@/store/modules/modalStorage";
import messagesStorage from "@/store/modules/messagesStorage";

import type { IPatient, IRecord, ICategory } from "@/sockets/SocketClients.types";

export default createStore({
  state: {
    socket: null,
    user: null,
    userLoaded: false,
    patientList: [] as IPatient[],
    patientListLoaded: false,
    selectedPatient: {} as IPatient,
    //newPatientModal: false,
    newUserModal: false,
    recentRecord: {} as IRecord,
    medsList: [],
    requestingPatient: [],
    pastRequestPatient: [],
    pendingRefills: [],
    pastRefills: [],
    refillsLoaded: null,
    currentPendingRefill: [],
    currentPastRefill: [],
    refillRequestModal: false,
    medsLoaded: null,
    medication: null,
    expiredMedication: null,
    filesList: [],
    filesListLoaded: false,
    labs: null,
    labsList: [],
    labsListLoaded: false,
    records: [],
    currentRecord: null,
    appointmentList: [],
    pendingAppointments: [],
    confirmedAppointments: [],
    pastAppointments: [],
    patientPending: [],
    appointmentListLoaded: null,
    newTimeModal: null,
    appointmentDetailsModal: null,
    currentPendingArray: [],
    currentPatientPendingArray: [],
    currentConfirmedArray: [],
    currentPastArray: [],
    currentTab: "none",
    role: "",
    categoriesList: [] as ICategory[],
  },
  getters: {},
  mutations: {
    SET_USER(state) {
      if (!state.socket) {
        state.socket = SocketClient.create(true);
        state.socket.keepalive(true);
      }
      state.socket.initGetUserListener((user) => {
        state.user = user;
      });
      state.socket.getUser();
    },
    USER_LOADED(state) {
      state.userLoaded = true;
    },
    SET_ROLE(state) {
      if (!state.socket) {
        state.socket = SocketClient.create(true);
        state.socket.keepalive(true);
      }
      state.socket.initGetRolesListListener((roles) => {
        state.role = roles.find((role) => role.id === state.user.role).title;
      });
      state.socket.getRolesList();
    },

    async SET_PATIENTLIST(state) {
      state.socket.initGetPatientListListener((patientList: IPatient[]) => {
        state.patientList = [] as IPatient[];
        patientList.forEach((patient: IPatient) => {
          state.patientList.push(patient);
        });
        state.patientListLoaded = true;
      });
      await state.socket.getPatientList();
    },
    DELETE_PATIENTLIST(state) {
      state.patientList = [];
    },
    TOGGLE_NEW_USER_MODAL(state) {
      state.newUserModal = !state.newUserModal;
    },
    TOGGLE_NEW_PATIENT_MODAL(state) {
      state.newPatientModal = !state.newPatientModal;
    },
    SET_RECENT_RECORD(state, payload) {
      return (state.recentRecord = payload);
    },

    SET_REFILLS(state, payload) {
      payload.data.forEach((med: any) => {
        if (med.refillStatus === "pending") {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          state.pendingRefills.push(med);
          state.patientList.forEach((patient) => {
            if (state.requestingPatient.includes(patient)) {
              return;
            }
            if (med.patientId === patient.id) {
              state.requestingPatient.push(patient);
              console.log(state.requestingPatient);
            }
          });
        }
        if (med.refillStatus === "past") {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          state.pastRefills.push(med);
          state.patientList.forEach((patient) => {
            if (state.pastRequestPatient.includes(patient)) {
              return;
            }
            if (med.patientId === patient.id) {
              state.pastRequestPatient.push(patient);
            }
          });
        }
      });
    },

    SET_REFILLS_LOADED(state) {
      state.refillsLoaded = true;
    },

    SET_CURRENT_PENDING_REFILL(state, payload) {
      state.currentPendingRefill = state.pendingRefills.filter(
        (refill) => refill.patientId === payload
      );
    },

    SET_CURRENT_PAST_REFILL(state, payload) {
      state.currentPastRefill = state.pastRefills.filter((refill) => refill.patientId === payload);
    },

    DELETE_REFILL_LISTS(state) {
      state.currentPendingRefill = [];
      state.currentPastRefill = [];
      state.pendingRefills = [];
      state.pastRefills = [];
      state.requestingPatient = [];
    },

    DELETE_CURRENT_REFILL_LISTS(state) {
      state.currentPendingRefill = [];
      state.currentPastRefill = [];
    },

    TOGGLE_REFILL_REQUEST_MODAL(state) {
      state.refillRequestModal = !state.refillRequestModal;
    },

    SET_MEDS(state, payload) {
      state.medication = payload.currentMeds.sort((a, b) => {
        if (!b.prescribedOn) {
          return -1;
        } else if (a.prescribedOn < b.prescribedOn) {
          return 1;
        } else if (a.prescribedOn > b.prescribedOn) {
          return -1;
        } else {
          return 0;
        }
      });
      state.expiredMedication = payload.expiredMeds.sort((a, b) => {
        if (!b.prescribedOn) {
          return -1;
        } else if (a.prescribedOn < b.prescribedOn) {
          return 1;
        } else if (a.prescribedOn > b.prescribedOn) {
          return -1;
        } else {
          return 0;
        }
      });
    },
    MEDS_LOADED(state) {
      state.medsLoaded = true;
    },
    SET_FILES(state, payload) {
      state.filesList = payload;
    },
    FILES_LIST_LOADED(state) {
      state.filesListLoaded = true;
    },
    SET_LABS(state, payload) {
      state.labs = payload;
    },
    LABS_LIST_LOADED(state) {
      state.labsListLoaded = true;
    },

    SET_RECORDS(state, payload) {
      state.records = payload.reverse();
    },

    SET_CURRENT_RECORD(state, payload) {
      state.currentRecord = payload;
    },

    SET_APPOINTMENTLIST(state, payload) {
      payload.data.forEach((appointment) => {
        if (appointment.status === "pending") {
          state.pendingAppointments.push(appointment);
        }
        if (appointment.status === "confirmed") {
          state.confirmedAppointments.push(appointment);
        }
        if (appointment.status === "past") {
          state.pastAppointments.push(appointment);
        }
        if (appointment.status === "patient pending") {
          state.patientPending.push(appointment);
        }
      });
    },

    APPOINTMENTLIST_LOADED(state) {
      state.appointmentListLoaded = true;
    },

    DELETE_APPOINTMENTLIST(state) {
      state.pendingAppointments = [];
      state.confirmedAppointments = [];
      state.pastAppointments = [];
      state.patientPending = [];
    },

    TOGGLE_NEW_TIME_MODAL(state) {
      state.newTimeModal = !state.newTimeModal;
    },

    TOGGLE_APPOINTMENT_DETAILS_MODAL(state) {
      state.appointmentDetailsModal = !state.appointmentDetailsModal;
    },

    SET_CURRENT_PENDING_APPOINTMENT(state, payload) {
      state.currentPendingArray = state.pendingAppointments.filter(
        (appointment) => appointment.id === payload
      );
    },

    SET_CURRENT_PATIENT_PENDING_APPOINTMENT(state, payload) {
      state.currentPatientPendingArray = state.patientPending.filter(
        (appointment) => appointment.id === payload
      );
    },

    SET_CURRENT_CONFIRMED_APPOINTMENT(state, payload) {
      state.currentConfirmedArray = state.confirmedAppointments.filter(
        (appointment) => appointment.id === payload
      );
    },

    SET_CURRENT_PAST_APPOINTMENT(state, payload) {
      state.currentPastArray = state.pastAppointments.filter(
        (appointment) => appointment.id === payload
      );
    },

    DELETE_CURRENT_APPOINTMENT_LISTS(state) {
      state.currentPendingArray = [];
      state.currentPatientPendingArray = [];
      state.currentConfirmedArray = [];
      state.currentPastArray = [];
    },
    SET_SELECTED_PATIENT(state, data) {
      state.selectedPatient = state.patientList.filter((patient) => patient.id === data)[0];
    },
    UNSET_RECORD(state) {
      console.log("Clearing Records.");
      state.recentRecord = null;
      state.currentRecord = null;
    },
    SET_CURRENT_TAB(state, data) {
      state.currentTab = data;
    },
    SET_DRAFT(state) {
      if (state.selectedPatient) {
        state.selectedPatient.draft = { cc: "Draft CC works." };
      }
    },
    async SET_CATEGORIES_LIST(state) {
      state.socket.initGetAllCategoriesListener((categories: ICategory[]) => {
        state.categoriesList = categories;
      });
      state.socket.getAllCategories();
    },
  },
  actions: {
    async GET_DRAFT({ commit }) {
      commit("SET_DRAFT");
    },
    async GET_USER({ commit }, data) {
      await commit("SET_USER", data);
      await commit("USER_LOADED");
      await commit("SET_ROLE");
    },

    async GET_SELECTED_PATIENT({ state, commit, dispatch }, data) {
      commit("UNSET_RECORD");
      if (!state.patientListLoaded) {
        dispatch("GET_PATIENTS");
        const interval = setInterval(() => {
          //Wait for PatientList incase of page reload
          console.log("Waiting for patients to load.");
          if (state.patientListLoaded) {
            commit("SET_SELECTED_PATIENT", data);
            clearInterval(interval);
          }
        }, 200);
      } else {
        commit("SET_SELECTED_PATIENT", data);
      }
    },

    async GET_PATIENTS({ commit }) {
      console.log("GET_PATIENTS(); Getting all patients.");
      await commit("SET_PATIENTLIST");
    },

    GET_RECENT_RECORD({ commit }, data) {
      this.state.recentRecord = null;
      commit("SET_RECENT_RECORD", data);
    },

    async GET_REFILLS({ commit }, data) {
      await commit("SET_REFILLS", data);
      await commit("SET_REFILLS_LOADED");
    },

    GET_MEDS({ commit }, data) {
      commit("SET_MEDS", data);
    },
    MEDS_LOADED(state) {
      state.medsLoaded = true;
    },
    SET_FILES(state, payload) {
      state.filesList = payload;
    },
    GET_FILES({ commit }, data) {
      commit("SET_FILES", data);
    },
    FILES_LIST_LOADED(state) {
      state.filesListLoaded = true;
    },
    SET_FILES(state, payload) {
      state.labsList = payload;
    },
    GET_LABS({ commit }, data) {
      commit("SET_LABS", data);
    },
    LABS_LIST_LOADED(state) {
      state.labsListLoaded = true;
    },

    GET_RECORDS({ commit }, data) {
      commit("SET_RECORDS", data);
    },

    GET_RECORD({ commit }, data) {
      commit("SET_CURRENT_RECORD", data);
    },

    GET_APPOINTMENTS({ commit }, data) {
      commit("SET_APPOINTMENTLIST", data);
      commit("APPOINTMENTLIST_LOADED");
    },

    async GET_CATEGORIES({ commit }) {
      await commit("SET_CATEGORIES_LIST");
    },
  },
  modules: {
    modal: modalStorage,
    messages: messagesStorage,
  },
});
