<template>
  <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <g id="icon / profile">
      <path
        id="Union"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.7961 7.05996C9.7961 5.44639 11.1042 4.13833 12.7177 4.13833C14.3313 4.13833 15.6394 5.44639 15.6394 7.05996C15.6394 8.67354 14.3313 9.9816 12.7177 9.9816C11.1042 9.9816 9.7961 8.67354 9.7961 7.05996ZM12.7177 2.54471C10.224 2.54471 8.20248 4.56626 8.20248 7.05996C8.20248 9.55367 10.224 11.5752 12.7177 11.5752C15.2114 11.5752 17.233 9.55367 17.233 7.05996C17.233 4.56626 15.2114 2.54471 12.7177 2.54471ZM4.63353 21.9747C4.71104 20.8121 5.20975 19.0254 6.44368 17.547C7.64975 16.1021 9.59528 14.9032 12.7001 14.9032C15.8049 14.9032 17.7505 16.1021 18.9565 17.547C20.1905 19.0254 20.6892 20.8121 20.7667 21.9747C20.7985 22.4528 21.212 22.8146 21.6901 22.7827C22.1682 22.7508 22.53 22.3374 22.4981 21.8593C22.4005 20.3947 21.7958 18.2407 20.2887 16.4351C18.7538 14.5961 16.3208 13.1679 12.7001 13.1679C9.07944 13.1679 6.6464 14.5961 5.11148 16.4351C3.60442 18.2407 2.99974 20.3947 2.9021 21.8593C2.87023 22.3374 3.23198 22.7508 3.7101 22.7827C4.18822 22.8146 4.60166 22.4528 4.63353 21.9747Z"
      />
    </g>
  </svg>
</template>
