import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-73d9d064"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-component" }
const _hoisted_2 = { class: "tw-w-[1536px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewTimeModal = _resolveComponent("NewTimeModal")!
  const _component_AppointmentDetailsModal = _resolveComponent("AppointmentDetailsModal")!
  const _component_RefillRequestModal = _resolveComponent("RefillRequestModal")!
  const _component_NewUserModal = _resolveComponent("NewUserModal")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_DefaultTemplate = _resolveComponent("DefaultTemplate")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.newTimeModal)
        ? (_openBlock(), _createBlock(_component_NewTimeModal, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.appointmentDetailsModal)
        ? (_openBlock(), _createBlock(_component_AppointmentDetailsModal, { key: 1 }))
        : _createCommentVNode("", true),
      (_ctx.refillRequestModal)
        ? (_openBlock(), _createBlock(_component_RefillRequestModal, { key: 2 }))
        : _createCommentVNode("", true),
      (_ctx.newUserModal)
        ? (_openBlock(), _createBlock(_component_NewUserModal, { key: 3 }))
        : _createCommentVNode("", true),
      (_ctx.useTemplate)
        ? (_openBlock(), _createBlock(_component_DefaultTemplate, { key: 4 }, {
            default: _withCtx(() => [
              _createVNode(_component_router_view)
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_router_view, { key: 5 }))
    ])
  ]))
}