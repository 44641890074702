export default class AuthService {
  static getUserId(): string {
    return localStorage.userId;
  }
  static setUserId(userId: string): void {
    localStorage.userId = userId;
  }

  static getMedAuthToken(): string {
    return localStorage.medAuthToken;
  }
  static setMedAuthToken(medAuthToken: string): void {
    localStorage.medAuthToken = medAuthToken;
  }

  static getPhoto(): string {
    return localStorage.photo;
  }
  static setPhoto(photo: string): void {
    localStorage.photo = photo;
  }

  static getDisplayName(): string {
    return localStorage.displayName;
  }
  static setDisplayName(displayName: string): void {
    localStorage.displayName = displayName;
  }

  static getFullName(): string {
    return localStorage.fullName;
  }
  static setFullName(fullName: string): void {
    localStorage.fullName = fullName;
  }

  static isAuthenticated(): boolean {
    return this.getMedAuthToken() != null && this.getUserId() != null;
  }

  static logout(): void {
    localStorage.removeItem("userId");
    localStorage.removeItem("medAuthToken");
    localStorage.removeItem("photo");
    localStorage.removeItem("displayName");
    localStorage.removeItem("fullName");
  }
}
